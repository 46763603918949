import { useRootStore } from '@/user/stores/root';
import difference from 'lodash/difference';
import { getCompanyFeatureFlag } from '@/common/company_feature_flags';

export default class Voter {
    get currentCompany () {
        return useRootStore().currentCompany;
    }

    hasValidCompany () {
        if ('ALL' === this.currentCompany) {
            return false;
        }

        if (0 === this.currentCompany.companyTypes.length) {
            return false;
        }

        // in the end, companies with only waste management or landfill or both can't be selected so this won't be needed
        // in other words, if they have any other company types, they can perform the action
        return difference(this.currentCompany.companyTypes, ['WASTE_MANAGEMENT', 'LANDFILL']).length > 0;
    }

    getValidCompanyMessage () {
        if ('ALL' === this.currentCompany) {
            return 'Select a company before performing this action.';
        }

        if (0 === this.currentCompany.companyTypes.length) {
            return 'Your current company has no company types (project roles). Please select a different company or contact EcoClaim for more information.';
        }

        // in the end, companies with only waste management can't be selected so this won't be needed
        if (0 === difference(this.currentCompany.companyTypes, ['WASTE_MANAGEMENT']).length) {
            return 'Your current company is a waste management company. Waste companies cannot perform this action. Please select a different company or contact EcoClaim for more information.';
        }
    }

    canCompanyModify () {
        if (!this.hasValidCompany()) {
            return false;
        }

        return this.currentCompany.active && !this.currentCompany.readonly;
    }

    hasUserCompanyRole (userCompanyRole) {
        const rootStore = useRootStore();

        if (!rootStore.loggedIn) {
            return false;
        }

        if ('ALL' === this.currentCompany) {
            return false;
        }

        if (rootStore.hasRole('ROLE_ADMIN')) {
            return true;
        }

        const currentUserCompany = rootStore.user.companies.find(
            (c) => c.companyId === rootStore.currentCompany.companyId
        );

        return userCompanyRole === currentUserCompany?.userCompanyRole;
    }

    companyFeatureFlag (featureFlag) {
        if (!this.hasValidCompany()) {
            return false;
        }

        return getCompanyFeatureFlag(this.currentCompany.featureFlags, featureFlag);
    }

    isProjectLead (project) {
        if (!this.hasValidCompany()) {
            return false;
        }

        // project doesn't have a lead (shouldn't happen)
        if (!project.projectLead)  {
            return false;
        }

        return project.projectLeadCompany.companyId === this.currentCompany.companyId;
    }

    /**
     * Checks if the user can view the project as the current company.
     * If they have All Companies selected, we use the server side checks to determine if they can view the project.
     *
     * @param {ProjectClass} project
     * @returns {boolean}
     */
    canViewProject (project) {
        // we assume that the server has checked if they have access to the project,
        // and we don't check if they're waste management or landfill
        if ('ALL' === this.currentCompany) {
            return true;
        }

        return project.hasCompany(this.currentCompany.companyId);
    }

    /**
     * Checks if the user can view the project as any company they have access to.
     * Returns the first company ID (as string) that they can view the project as.
     * If admin, just switch to the first company on the project.
     *
     * @param {ProjectClass} project
     * @return {string|undefined}
     */
    canViewProjectAsUser (project) {
        if (useRootStore().hasRole('ROLE_ADMIN')) {
            return project.companies[0]?.company.companyId;
        }

        return useRootStore().user.companies.find((company) => {
            return project.hasCompany(company.companyId);
        })?.companyId;
    }

    /**
     * Determine if the current user/company can edit the project.
     * Must have company selected, company must not be readonly.
     * The project status must be open & current company must be the project lead.
     *
     * @param {ProjectClass} project
     * @returns {boolean}
     */
    canEditProject (project) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen) {
            return false;
        }

        return this.isProjectLead(project);
    }

    canReopenProject (project) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isClosed) {
            return false;
        }

        return this.isProjectLead(project);
    }

    /**
     * The current company must be the project lead or the projectCompany is the same as the current company.
     *
     * @param {ProjectClass} project
     * @param {ProjectCompanyClass} projectCompany
     * @returns {boolean}
     */
    canEditProjectCompany (project, projectCompany) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen) {
            return false;
        }

        if (this.isProjectLead(project)) {
            return true;
        }

        if (projectCompany.isCurrentCompany) {
            return projectCompany.accepted;
        }

        return false;
    }

    /**
     * @param {ProjectClass} project
     * @param {ProjectCompanyClass} projectCompany
     * @returns {boolean}
     */
    canAcceptDeclineProjectCompany (project, projectCompany) {
        // project must be open and project company pending to accept or decline
        if (!(project.isOpen && projectCompany.pending)) {
            return false;
        }

        return projectCompany.isCurrentCompany;
    }

    /**
     * @param {ProjectClass} project
     * @param {JobClass|null} job
     * @returns {boolean}
     */
    canAddNote (project, job = null) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen) {
            return false;
        }

        if (!project.hasCompanyAndAccepted(this.currentCompany.companyId)) {
            return false;
        }

        if (null === job) {
            return true;
        }

        return this.canEditJob(project, job);
    }

    /**
     * Same as add note, but also checks if the note is from the current company.
     *
     * @param {ProjectClass} project
     * @param {JobClass|null} job
     * @param {object} note
     * @returns {boolean}
     */
    canEditNote (project, job = null, note) {
        // do this check first to ensure a company is selected
        if (!this.canCompanyModify()) {
            return false;
        }

        if (note.company.companyId !== this.currentCompany.companyId) {
            return false;
        }

        return this.canAddNote(project, job);
    }

    /**
     * @param {ProjectClass} project
     * @returns {boolean}
     */
    canAddJob (project) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen) {
            return false;
        }

        if (this.isProjectLead(project)) {
            return true;
        }

        // they already have the role of contractor & have accepted
        return project.getCompanyWithRole(this.currentCompany.companyId, 'CONTRACTOR')?.accepted;
    }

    /**
     * @param {ProjectClass} project
     * @param {JobClass} job
     * @returns {boolean}
     */
    canEditJob (project, job) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen)  {
            return false;
        }

        if (this.isProjectLead(project)) {
            return job.isPending || job.isInProgress;
        }

        if (job.company.companyId === this.currentCompany.companyId) {
            return job.isInProgress;
        }

        return false;
    }

    /**
     * @param {ProjectClass} project
     * @param {JobClass} job
     * @returns {boolean}
     */
    canAcceptDeclineJob (project, job) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!(project.isOpen && job.isPending)) {
            return false;
        }

        return job.company.companyId === this.currentCompany.companyId;
    }

    /**
     * @param {ProjectClass} project
     * @param {JobClass} job
     * @returns {boolean}
     */
    canCancelJob (project, job) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen) {
            return false;
        }

        if (this.isProjectLead(project)) {
            return true;
        }

        if (job.company.companyId === this.currentCompany.companyId) {
            return job.isInProgress;
        }

        return false;
    }

    /**
     * @param {ProjectClass} project
     * @param {JobClass} job
     * @returns {boolean}
     */
    canReopenJob (project, job) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen || !job.isClosed) {
            return false;
        }

        return this.isProjectLead(project) || job.company.companyId === this.currentCompany.companyId;
    }

    /**
     * @param {ProjectClass} project
     * @param {JobClass} job
     * @returns {boolean}
     */
    canAddEditTrip (project, job) {
        if (!this.canCompanyModify()) {
            return false;
        }

        if (!project.isOpen || !job.isInProgress) {
            return false;
        }

        return this.isProjectLead(project) || job.company.companyId === this.currentCompany.companyId;
    }
}
