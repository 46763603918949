export const companyFeatureFlags = {
    CONTENTS: 'Contents',
    ENERGY: 'Energy',
    SHOP_YARD: 'Shop/Yard',
};

// these defaults are also in \App\Model\Company\CompanyFeatureFlags
export const companyFeatureFlagsDefaults = {
    CONTENTS: false,
    ENERGY: false,
    SHOP_YARD: false,
};

export const getCompanyFeatureFlag = (featureFlags, featureFlag) => {
    const companyFeatureFlag = featureFlags.find((flag) => flag.featureFlag === featureFlag);

    if (companyFeatureFlag) {
        return companyFeatureFlag.flagValue;
    }

    return companyFeatureFlagsDefaults[featureFlag];
};
