/*global Weglot*/
import { useHead } from '@unhead/vue';

export const currentLang = () => {
    return Weglot.getCurrentLang().toUpperCase();
}

export const translate = (string) => {
    return new Promise((resolve) => {
        if (!window.Weglot || 'EN' === currentLang()) {
            resolve(string);
        }

        Weglot.translate(
            {
                // 1 = TEXT, full list here: https://developers.weglot.com/api/reference#wordtype
                'words': [ { 't': 9, 'w': string } ],
                'languageTo': currentLang().toLowerCase(),
            },
            function (data) {
                resolve(data[0]);
            },
        );
    });
};

export const translateDynamicPageTitle = (defaultTitle, callback) => {
    if ('EN' !== currentLang()) {
        useHead({
            title: translate(defaultTitle),
        });
    } else {
        useHead({
            title: callback,
        });
    }
};
